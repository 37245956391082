// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-ede-js": () => import("./../../../src/pages/ede.js" /* webpackChunkName: "component---src-pages-ede-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isa-js": () => import("./../../../src/pages/isa.js" /* webpackChunkName: "component---src-pages-isa-js" */),
  "component---src-pages-kids-js": () => import("./../../../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-payment-plan-js": () => import("./../../../src/pages/payment_plan.js" /* webpackChunkName: "component---src-pages-payment-plan-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-scholarship-js": () => import("./../../../src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success_stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-ux-ui-design-js": () => import("./../../../src/pages/ux-ui-design.js" /* webpackChunkName: "component---src-pages-ux-ui-design-js" */)
}

